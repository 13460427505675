var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navbar navbar-expand-lg navbar-light" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("a", { staticClass: "navbar-brand", attrs: { href: "#" } }, [
        _vm._v(_vm._s(_vm.routeName)),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "navbar-toggler navbar-burger",
          attrs: {
            type: "button",
            "aria-expanded": _vm.$sidebar.showSidebar,
            "aria-label": "Toggle navigation",
          },
          on: { click: _vm.toggleSidebar },
        },
        [
          _c("span", { staticClass: "navbar-toggler-bar" }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-toggler-bar" }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-toggler-bar" }),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "collapse navbar-collapse" }, [
      _c("ul", { staticClass: "navbar-nav ml-auto" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c("a", { staticClass: "nav-link", attrs: { href: "#" } }, [
            _c("i", { staticClass: "ti-panel" }),
            _vm._v(" "),
            _c("p", [_vm._v("Stats")]),
          ]),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c("a", { staticClass: "nav-link", attrs: { href: "#" } }, [
            _c("i", { staticClass: "ti-settings" }),
            _vm._v(" "),
            _c("p", [_vm._v("Settings")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }