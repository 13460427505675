var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-navbar",
        {
          staticClass: "bg-primary",
          attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
        },
        [
          _c(
            "b-navbar-brand",
            { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
            [
              _c("span", { staticClass: "logo-img" }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "navbar-title",
                  domProps: { textContent: _vm._s(_vm.$t("global.title")) },
                },
                [_vm._v("keypasco Saas")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-navbar-toggle",
            {
              staticClass: "jh-navbar-toggler d-lg-none",
              attrs: {
                right: "",
                href: "javascript:void(0);",
                "data-toggle": "collapse",
                target: "header-tabs",
                "aria-expanded": "false",
                "aria-label": "Toggle navigation",
              },
            },
            [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { "is-nav": "", id: "header-tabs" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _vm.authenticated
                    ? _c(
                        "b-nav-item-dropdown",
                        {
                          staticClass: "pointer",
                          attrs: {
                            right: "",
                            id: "entity-menu",
                            "active-class": "active",
                            "data-cy": "entity",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "navbar-dropdown-menu",
                              attrs: { slot: "button-content" },
                              slot: "button-content",
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "th-list" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "no-bold",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.main")
                                    ),
                                  },
                                },
                                [_vm._v("Entities")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("entities-menu"),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
                    ? _c(
                        "b-nav-item-dropdown",
                        {
                          staticClass: "pointer",
                          class: {
                            "router-link-active": _vm.subIsActive("/admin"),
                          },
                          attrs: {
                            right: "",
                            id: "admin-menu",
                            "active-class": "active",
                            "data-cy": "adminMenu",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "navbar-dropdown-menu",
                              attrs: { slot: "button-content" },
                              slot: "button-content",
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "users-cog" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "no-bold",
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.main")
                                    ),
                                  },
                                },
                                [_vm._v("Administration")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/user-management",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "users" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.userManagement")
                                    ),
                                  },
                                },
                                [_vm._v("User management")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/metrics",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "tachometer-alt" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.metrics")
                                    ),
                                  },
                                },
                                [_vm._v("Metrics")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/health",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "heart" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.health")
                                    ),
                                  },
                                },
                                [_vm._v("Health")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/configuration",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "cogs" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.configuration")
                                    ),
                                  },
                                },
                                [_vm._v("Configuration")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/logs",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "tasks" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.logs")
                                    ),
                                  },
                                },
                                [_vm._v("Logs")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.openAPIEnabled
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    to: "/admin/docs",
                                    "active-class": "active",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "book" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("global.menu.admin.apidocs")
                                        ),
                                      },
                                    },
                                    [_vm._v("API")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.inProduction
                            ? _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    href: "http://localhost:8092/",
                                    target: "_tab",
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "database" },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("global.menu.admin.database")
                                        ),
                                      },
                                    },
                                    [_vm._v("Database")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/device-control",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "users" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.devicecontrol")
                                    ),
                                  },
                                },
                                [_vm._v("Device Control")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _vm.subIsActive("/account"),
                      },
                      attrs: {
                        right: "",
                        href: "javascript:void(0);",
                        id: "account-menu",
                        "active-class": "active",
                        "data-cy": "accountMenu",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "user" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.main")
                                ),
                              },
                            },
                            [_vm._v(" Account ")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "data-cy": "settings",
                                to: "/account/settings",
                                tag: "b-dropdown-item",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "wrench" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.account.settings")
                                    ),
                                  },
                                },
                                [_vm._v("Settings")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "data-cy": "passwordItem",
                                to: "/account/password",
                                tag: "b-dropdown-item",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "lock" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.account.password")
                                    ),
                                  },
                                },
                                [_vm._v("Password")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "data-cy": "logout",
                                id: "logout",
                                "active-class": "active",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.logout()
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "sign-out-alt" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.account.logout")
                                    ),
                                  },
                                },
                                [_vm._v("Sign out")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "data-cy": "login",
                                id: "login",
                                "active-class": "active",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openLogin()
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "sign-in-alt" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.account.login")
                                    ),
                                  },
                                },
                                [_vm._v("Sign in")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.authenticated
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                "data-cy": "register",
                                to: "/register",
                                tag: "b-dropdown-item",
                                id: "register",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "user-plus" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.account.register")
                                    ),
                                  },
                                },
                                [_vm._v("Register")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "register-login-btn" }, [
                    _c("div", { staticClass: "slide-controlss" }, [
                      _c("input", {
                        attrs: {
                          type: "radio",
                          name: "slide",
                          id: "log-inn",
                          checked: "",
                        },
                        on: { click: _vm.loginSwitchBtnClick },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: { type: "radio", name: "slide", id: "signup" },
                        on: { click: _vm.handleLabelClick },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "slide login",
                          attrs: { for: "log-inn" },
                        },
                        [_vm._v("Login")]
                      ),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "slide signup",
                          attrs: { for: "signup" },
                        },
                        [_vm._v("Signup")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "slider-tab" }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.languages && Object.keys(_vm.languages).length > 1
                    ? _c(
                        "b-nav-item-dropdown",
                        { attrs: { id: "languagesnavBarDropdown", right: "" } },
                        [
                          _c(
                            "span",
                            {
                              attrs: { slot: "button-content" },
                              slot: "button-content",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/../content/images/LYDSEC-Keypasco SaaS-Language.png",
                                  alt: "footer logo picture",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.languages, function (value, key) {
                            return _c(
                              "b-dropdown-item",
                              {
                                key: "lang-" + key,
                                class: { active: _vm.isActiveLanguage(key) },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeLanguage(key)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(value.name) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }