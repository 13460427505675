// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import {ToastPlugin} from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LifeCycleManagementService from './dashboard/pages/lifeCycleManagement/lifeCycleManagement.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import ActivateService from './account/activate/activate.service';
import RegisterService from './account/register/register.service';
import UserManagementService from '@/admin/user-management/user-management.service';
import DeviceControlService from './admin/device-control/device-control.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';
import '../content/scss/app.scss'
import '../content/scss/global.scss';
import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';
import UserOAuth2Service from '@/entities/user/user.oauth2.service';
import {BootstrapVue, IconsPlugin, CalendarPlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import moment from 'moment';
import sidebar from './sidebarPlugin/sideBar.vue';
import sidebarLydsecAdmin from './sidebarPlugin/sideBarLydsecAdmin.vue';
import content from './dashboard/content.vue'
import topNavbar from "@/dashboard/topNavbar.vue";

/* tslint:disable */
// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here
/* tslint:enable */

/** Vue Leaflet **/
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
/** END of Vue Leaflet **/

Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);

Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(CalendarPlugin);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('dashboard-content', content);
Vue.component('top-navbar', topNavbar);
Vue.component('side-bar', sidebar);
Vue.component('sidebar-lydsec-admin', sidebarLydsecAdmin);

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();
const accountService = new AccountService(store, translationService, router);

router.beforeEach((to, from, next) => {
	if (!to.matched.length) {
		next('/not-found');
	}

	if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
		accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
			if (!value) {
				sessionStorage.setItem('requested-url', to.fullPath);
				next('/forbidden');
			} else {
				next();
			}
		});
	} else {
		// no authorities, so just proceed
		next();
	}
});

/* tslint:disable */
new Vue({
	el: '#app',
	components: {App},
	template: '<App/>',
	router,
	// data: {
	//   SidebarStore: SidebarStore,
	//     },
	provide: {
		loginService: () => loginService,
		activateService: () => new ActivateService(),
		registerService: () => new RegisterService(),
		userService: () => new UserManagementService(),
		healthService: () => new HealthService(),
		configurationService: () => new ConfigurationService(),
		logsService: () => new LogsService(),
		metricsService: () => new MetricsService(),
		lifeCycleManagementService: () => new LifeCycleManagementService(),
		deviceService: () => new DeviceControlService(),
		userOAuth2Service: () => new UserOAuth2Service(),
		translationService: () => translationService,
		// jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
		accountService: () => accountService,
		alertService: () => new AlertService(),
	},
	i18n,
	store,
});
