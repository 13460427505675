import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router from 'vue-router';
const Dashboard = () => import('@/dashboard/dashboardLayout.vue');
// const TableList = () => import('@/dashboard/pages/tableList.vue');
const CustomerAccountManagement = () => import('@/dashboard/pages/CustomerAccountManagement/customerAccountManagement.vue');
const UserDeviceManagement = () => import('@/dashboard/pages/UserDeviceManagement/userDeviceManagement.vue');
const LifeCycleManagement = () => import('@/dashboard/pages/lifeCycleManagement/lifeCycleManagement.vue');
const MultiFactorAuthentication = () => import('@/dashboard/pages/multiFactorAuthentication/multiFactorAuthentication.vue');
const GeolocationSettings = () => import('@/dashboard/pages/geolocationSettings/geolocationSettings.vue');
const UserAndGroupManagement = () => import('@/dashboard/pages/userAndGroupManagement/userAndGroupManagement.vue');
const Home = () => import('@/core/home/home.vue');
const Login = () => import('@/account/login/login.vue');
const Error = () => import('@/core/error/error.vue');
import account from '@/router/account';
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';

Vue.use(Router);

// prettier-ignore
export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
    },
    {
      path: '/dashboard/user-device-management',
      name: 'UserDeviceManagement',
      component: UserDeviceManagement,
    },
    {
      path: '/dashboard/life-cycle-management',
      name: 'LifeCycleManagement',
      component: LifeCycleManagement
    },
    {
      path: '/dashboard/customer-account-management',
      name: 'CustomerAccountManagement',
      component: CustomerAccountManagement
    },
    {
      path: '/dashboard/multi-factor-authentication',
      name: 'MultiFactorAuthentication',
      component: MultiFactorAuthentication
    },
    {
      path: '/dashboard/geolocation-settings',
      name: 'GeolocationSettings',
      component: GeolocationSettings
    },
    {
      path: '/dashboard/user-And-Group-Management',
      name: 'user-And-Group-Management',
      component: UserAndGroupManagement
    },
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true }
    },
    ...account,
    ...admin,
    ...entities,
    ...pages
  ]
});
