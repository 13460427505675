var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sidebar" }, [
    _c("div", { staticClass: "sidebar-wrapper", attrs: { id: "style-3" } }, [
      _c("div", { staticClass: "logo" }, [
        _c("a", { staticClass: "simple-text", attrs: { href: "#" } }, [
          _vm._m(0),
          _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "sidebar-list" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-1-inner",
                  modifiers: { "collapse-1-inner": true },
                },
              ],
              staticClass: "sidebarBtn",
              attrs: { size: "lg" },
            },
            [
              _c(
                "a",
                { staticClass: "sidebarBtn-a", attrs: { href: "/dashboard" } },
                [_vm._v("Dashboard")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-inner-serviceManagement",
                  modifiers: { "collapse-inner-serviceManagement": true },
                },
              ],
              staticClass: "sidebarBtn",
              attrs: { size: "md" },
            },
            [_vm._v("使用者設備管理")]
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            {
              staticClass: "mt-2",
              attrs: { id: "collapse-inner-serviceManagement" },
            },
            [
              _c("div", { staticClass: "list-group" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      href: "/dashboard/user-device-management",
                      role: "button",
                      "data-bs-toggle": "button",
                    },
                  },
                  [_vm._v("已綁定設備")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      href: "/dashboard/geolocation-settings",
                      role: "button",
                      "data-bs-toggle": "button",
                    },
                  },
                  [_vm._v("地理位置設定")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      href: "/dashboard",
                      role: "button",
                      "data-bs-toggle": "button",
                    },
                  },
                  [_vm._v("認證時間")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      href: "/dashboard/multi-factor-authentication",
                      role: "button",
                      "data-bs-toggle": "button",
                    },
                  },
                  [_vm._v("多重認證")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      href: "/dashboard/life-cycle-management",
                      role: "button",
                      "data-bs-toggle": "button",
                    },
                  },
                  [_vm._v("生命週期管理")]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-1-inner",
                  modifiers: { "collapse-1-inner": true },
                },
              ],
              staticClass: "sidebarBtn",
              attrs: { size: "md" },
            },
            [
              _c(
                "a",
                {
                  staticClass: "sidebarBtn-a",
                  attrs: { href: "/dashboard/user-And-Group-Management" },
                },
                [_vm._v("使用者群組管理")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-1-inner",
                  modifiers: { "collapse-1-inner": true },
                },
              ],
              staticClass: "sidebarBtn",
              attrs: { size: "md" },
            },
            [
              _c(
                "a",
                { staticClass: "sidebarBtn-a", attrs: { href: "/dashboard" } },
                [_vm._v("報表")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-1-inner",
                  modifiers: { "collapse-1-inner": true },
                },
              ],
              staticClass: "sidebarBtn",
              attrs: { size: "md" },
            },
            [
              _c(
                "a",
                { staticClass: "sidebarBtn-a", attrs: { href: "/dashboard" } },
                [_vm._v("線上刷卡及報表")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-1-inner",
                  modifiers: { "collapse-1-inner": true },
                },
              ],
              staticClass: "sidebarBtn",
              attrs: { size: "md" },
            },
            [
              _c(
                "a",
                { staticClass: "sidebarBtn-a", attrs: { href: "/dashboard" } },
                [_vm._v("開發者平台")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-1-inner",
                  modifiers: { "collapse-1-inner": true },
                },
              ],
              staticClass: "sidebarBtn",
              attrs: { size: "md" },
            },
            [
              _c(
                "a",
                { staticClass: "sidebarBtn-a", attrs: { href: "/dashboard" } },
                [_vm._v("軟體資訊及下載頁面")]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-1-inner",
                  modifiers: { "collapse-1-inner": true },
                },
              ],
              staticClass: "sidebarBtn",
              attrs: { size: "md" },
            },
            [
              _c(
                "a",
                { staticClass: "sidebarBtn-a", attrs: { href: "/dashboard" } },
                [_vm._v("系統配置管理")]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-img" }, [
      _c("img", {
        attrs: {
          src: require("../../content/images/LYDSEC-Keypasco- SaaS-Key-Header.png"),
          alt: "keypasco logo",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }