import axios from 'axios';

import { TUser } from '../../../shared/model/timeControl.model';
export default class LifeCycleManagementService {
	public TimeCreate(Period: TUser): Promise<any> {
		return axios.post('api/borgen/customer/createValidityPeriod', Period);
	}

	public TimeList(Period: TUser): Promise<any> {
		return axios.post('api/borgen/customer/listValidityPeriod', Period);
	}

	public TimeUpdate(Period: TUser): Promise<any> {
		return axios.post('api/borgen/customer/updateValidityPeriod', Period);
	}

	public TimeDelete(Period: TUser): Promise<any> {
		return axios.post('api/borgen/customer/deleteValidityPeriod', Period);
	}

	public UnRegister(Period: TUser): Promise<any> {
		return axios.post('api/borgen/customer/unregister', Period);
	}

	public ActivateUser(Period: TUser): Promise<any> {
		return axios.post('api/borgen/customer/activateUser', Period);
	}
}
