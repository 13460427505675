<template>
  <div
    class="sidebar"
    :data-background-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <!--
            Tip 1: you can change the color of the sidebar's background using: data-background-color="white | black | darkblue"
            Tip 2: you can change the color of the active button using the data-active-color="primary | info | success | warning | danger"
        -->
    <!-- -->
    <div class="sidebar-wrapper" id="style-3">
      <div class="logo">
        <a href="#" class="simple-text">
          <div class="logo-img">
            <img src="../../content/images/LYDSEC-Keypasco- SaaS-Key-Header.png" alt="keypasco logo" />
          </div>
          {{ title }}
        </a>
      </div>
      <ul class="sidebar-list">
        <b-button v-b-toggle.collapse-1-inner class="sidebarBtn" size="lg">
          <a class="sidebarBtn-a" href="/dashboard">Dashboard</a>
        </b-button>
        <b-button v-b-toggle.collapse-inner-serviceManagement  size="md" class="sidebarBtn">使用者設備管理</b-button>
        <b-collapse id="collapse-inner-serviceManagement" class="mt-2">
          <div class="list-group">
            <a href="/dashboard/user-device-management" class="list-group-item list-group-item-action" aria-current="true">
              已綁定設備
            </a>
            <a href="/dashboard" class="list-group-item list-group-item-action">地理位置設定</a>
            <a href="/dashboard" class="list-group-item list-group-item-action">認證時間</a>
            <a href="/dashboard" class="list-group-item list-group-item-action">多重認證</a>
          </div>
        </b-collapse>
        <b-button v-b-toggle.collapse-1-inner class="sidebarBtn" size="md">
          <a class="sidebarBtn-a" href="/dashboard">開發者平台</a>
        </b-button>

        <b-button v-b-toggle.collapse-1-inner class="sidebarBtn" size="md">
          <a class="sidebarBtn-a" href="/dashboard">軟體資訊及下載頁面</a>
        </b-button>

        <b-button v-b-toggle.collapse-1-inner class="sidebarBtn" size="md">
          <a class="sidebarBtn-a" href="/dashboard">開發者平台</a>
        </b-button>

        <b-button v-b-toggle.collapse-inner-operation  size="md" class="sidebarBtn">營運管理介面</b-button>
        <b-collapse id="collapse-inner-operation" class="mt-2">
          <div class="list-group">
            <a href="/dashboard/customer-account-management" class="list-group-item list-group-item-action">軟體管理</a>
            <a href="/dashboard" class="list-group-item list-group-item-action" aria-current="true">
              系統設定
            </a>
            <a href="/dashboard" class="list-group-item list-group-item-action">Billing 管理</a>
            <a href="/dashboard" class="list-group-item list-group-item-action">系統狀態</a>
          </div>
        </b-collapse>
      </ul>
    </div>

  </div>
</template>
<script>
// import SidebarLink from "./sidebarlink.vue";
import { RouterLink } from 'vue-router'
import Router from 'vue-router';
export default {
  props: {
    title: {
      type: String,
      default: "Paper Dashboard",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["white", "black", "darkblue"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    activeColor: {
      type: String,
      default: "success",
      validator: (value) => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    // sidebarLinks: {
    //   type: Array,
    //   default: () => [],
    // },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
      addLink: this.addLink,
      removeLink: this.removeLink,
    };
  },
  components: {
    // movingarrow,
    // SidebarLink,
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx() {
      return this.linkHeight * this.activeLinkIndex;
    },
  },
  data() {
    return {
      linkHeight: 65,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
      links: [],
    };
  },
  methods: {
    findActiveLink() {
      this.links.forEach((link, index) => {
        if (link.isActive()) {
          this.activeLinkIndex = index;
        }
      });
    },
    addLink(link) {
      const index = this.$slots.links.indexOf(link.$vnode);
      this.links.splice(index, 0, link);
    },
    removeLink(link) {
      const index = this.links.indexOf(link);
      if (index > -1) {
        this.links.splice(index, 1);
      }
    },
  },
  mounted() {
    this.$watch("$route", this.findActiveLink, {
      immediate: true,
    });
  },
};
</script>
<style>
</style>
