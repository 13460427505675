import axios from 'axios';
import { DUser } from '@/shared/model/deviceControl.model';
export default class DeviceControlService {
  public processListDevice(user: DUser): Promise<any> {
    //console.log(user);
    return axios.post('/api/borgen/customer/listDevices', user);
    /*return axios.post('/api/borgen/customer/listDevices', user, {
      headers: {
        'content-type': 'application/json',
      },
    });*/
  }
  public processGenerateAssociationCode(user: DUser): Promise<any> {
	  //console.log(user);
	  return axios.post('/api/borgen/customer/generateAssociationCode', user);
  }
  public processRemoveDevice(user: DUser): Promise<any> {
    //console.log(user);
    return axios.post('/api/borgen/customer/deleteDevice', user);
  }
	public processUpdateDevice(user: DUser): Promise<any> {
		//console.log(user);
		return axios.post('/api/borgen/customer/updateDevice', user);
	}
}
