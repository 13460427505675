import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Pricing = () => import('@/pricing/pricing.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  {
    path: '/',
    component: Entities,
    children: [
      // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
    ],
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing,
  },
];
