var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer", attrs: { id: "footer" } }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-start row footer-row" },
        [
          _c("div", { staticClass: "col-md-1" }, [
            _c("div", { staticClass: "footer-logo" }, [
              _c("img", {
                attrs: {
                  src: "/../content/images/LYDSEC-Keypasco－SaaS－LYDSEC －Footer－Logo.png",
                  alt: "footer logo picture",
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-11" }, [
            _c("div", { staticClass: "footer-links" }, [
              _c("div", { staticClass: "grid-container" }, [
                _c("div", { staticClass: "item6" }, [
                  _vm._v("TEL +886223463366"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item7" }, [
                  _vm._v("Email services@lydsec.com"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer-links-2" }, [
                _c("div", { staticClass: "item8" }, [
                  _vm._v(
                    "Copyright © 2023 LYDSEC Digital Tech nology. All Rights Reserved."
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }