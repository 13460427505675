import { Component, Inject, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import TranslationService from '@/locale/translation.service';
import EntitiesMenu from '@/entities/entities-menu.vue';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
  },
})
export default class JhiNavbar extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;

  @Inject('accountService') private accountService: () => AccountService;
  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValue = false;

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  public handleLabelClick() {
    location.href = "/register";
    const element = document.querySelector('slider-tab') as HTMLElement;
    element.style.setProperty('left', '50%');
    console.log('do slider-tab')

  }

  // handleLabelClick(href: string, left: string): void {
  //   // 设置 location.href 属性
  //   location.href = href;
  //   console.log('do href')
  //   // 设置元素的 left 样式属性
  //   setTimeout(() => {
  //     // 设置元素的 left 样式属性
  //     const element = document.querySelector('slider-tab') as HTMLElement;
  //     element.style.setProperty('left', left);
  //   }, 100);
  //   console.log('do slider-tab')
  // }


  public loginSwitchBtnClick () {
    location.href = "/login";
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): void {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    this.$router.push('/');
  }

  public openLogin(): void {
    this.loginService().openLogin((<any>this).$root);
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValue = value;
      });
    return this.hasAnyAuthorityValue;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }


  // public slideLogin(): void {
  //   document.querySelector("form.login").style.marginLeft = "0%";
  //   document.querySelector(".title-text .login").style.marginLeft = "0%";
  // }
  //
  // public slideSignup(): void {
  //   document.querySelector("form.login").style.marginLeft = "-50%";
  //   document.querySelector(".title-text .login").style.marginLeft = "-50%";
  // }
}
